<!-- 用户须知 -->
<template>
  <div class="readme">
    <el-row>
      <el-col :span="4" class="sider">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <div class="collapse-item__title">新手上路</div>
            </template>
            <ul class="collapse-item__ul">
              <li
                v-for="(item, k) in guideList"
                :key="k"
                @click="changeIframeUrl(item.url)"
              >
                {{ item.title }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <div class="collapse-item__title">代发服务</div>
            </template>
            <ul class="collapse-item__ul">
              <li
                v-for="(item, k) in serviceList"
                :key="k"
                @click="changeIframeUrl(item.url)"
              >
                {{ item.title }}
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="20" style="min-height: 1000px">
        <iframe
          :src="iframeUrl"
          width="100%"
          :height="iframeHeight"
          scrolling="auto"
          frameborder="0"
          class="iframeContent"
          ref="iframeContent"
        ></iframe>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ReadMe",
  data() {
    return {
      activeName: "1",
      guideList: [
        {
          title: "商品退换货须知",
          url: "https://erp.toysmodel.cn:11443/detail/28",
        },
        {
          title: "怎么查物流信息",
          url: "https://erp.toysmodel.cn:11443/detail/27",
        },
        {
          title: "店主进货须知（常见问题）",
          url: "https://erp.toysmodel.cn:11443/detail/26",
        },
        {
          title: "网店进货必读",
          url: "https://erp.toysmodel.cn:11443/detail/25",
        },
        {
          title: "售后申请流程",
          url: "https://erp.toysmodel.cn:11443/detail/24",
        },
        {
          title: "电脑PC端注册下单流程",
          url: "https://erp.toysmodel.cn:11443/detail/23",
        },
        {
          title: "手机移动端注册下单流程",
          url: "https://erp.toysmodel.cn:11443/detail/22",
        },
        {
          title: "自定义发货信息",
          url: "https://erp.toysmodel.cn:11443/detail/21",
        },
        {
          title: "订单状态说明",
          url: "https://erp.toysmodel.cn:11443/detail/20",
        },
      ],
      serviceList: [
        {
          title: "快递费用标准",
          url: "https://erp.toysmodel.cn:11443/detail/29",
        },
        {
          title: "代发费用标准",
          url: "https://erp.toysmodel.cn:11443/detail/19",
        },
        {
          title: "质检发货标准",
          url: "https://erp.toysmodel.cn:11443/detail/18",
        },
        {
          title: "商家特色增值服务",
          url: "https://erp.toysmodel.cn:11443/detail/17",
        },
        {
          title: "发货时间及缺货处理",
          url: "https://erp.toysmodel.cn:11443/detail/16",
        },
        {
          title: "退换货流程指南",
          url: "https://erp.toysmodel.cn:11443/detail/15",
        },
        {
          title: "代发服务协议",
          url: "https://erp.toysmodel.cn:11443/detail/4",
        },
      ],
      iframeHeight: 2500,
      iframeUrl: "https://erp.toysmodel.cn:11443/detail/29",
    };
  },
  mounted() {
    window.addEventListener("message", (e) => {
        if (e.data && e.data.type == 'size') {
          this.iframeHeight = e.data.height + 50
        }
      });
  },
  methods: {
    /**
     * 切换右侧链接
     * @param {*} url
     */
    changeIframeUrl(url) {
      this.iframeUrl = url;
      this.resetHeight();
    },
    /**
     * 设置长度
     */
    resetHeight() {
      window.addEventListener("message", (e) => {
        if (e.data && e.data.type == 'size') {
          // console.log(e.data.height)
          this.iframeHeight = e.data.height + 50
        }
      });
    },
  },
};
</script>

<style scoped>
.readme {
  width: 1225px;
  font-size: 14px;
  margin: 0 auto;
}
.collapse-item__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
}
.collapse-item__ul {
  padding: 5px 0 0 0;
}
.collapse-item__ul li {
  color: #757578;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 1em;
  cursor: pointer;
}
.iframeContent {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 auto;
  background: #fff;
}
</style>
